import { COLORS, SPACING } from 'helpers/globals'
import { hideAtLg, styleUpToLg } from 'helpers/screens'
import slashes from 'assets/images/team-hero-slashes.svg'

export default {
  container: {
    backgroundColor: props => (props.plain ? '' : COLORS.lightestGrey)
  },
  innerContainer: {
    marginTop: props => (props.plain ? '' : `-${SPACING.large}`)
  },
  angleContainer: {
    overflow: 'hidden'
  },
  angle: {
    marginLeft: '-10px',
    width: '75%',
    transform: 'skew(-20deg)',
    height: SPACING.large,
    backgroundColor: COLORS.red,
    ...hideAtLg
  },
  mailIcon: {
    display: 'block',
    margin: `${SPACING.large} auto`,
    ...styleUpToLg({
      width: SPACING.xxxl,
      paddingBottom: SPACING.base
    })
  },
  left: {
    backgroundImage: `url(${slashes})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '25px 95%',
    backgroundSize: '50rem',
    position: 'relative',
    zIndex: 1,
    backgroundColor: COLORS.red,
    marginTop: '-1px',
    marginBottom: '-1px',
    ...styleUpToLg({
      marginTop: props => (props.plain ? '' : `-${SPACING.large}`)
    })
  },
  right: {
    position: 'relative',
    zIndex: 3,
    backgroundColor: COLORS.white,
    padding: `${SPACING.xl} !important`,
    ...styleUpToLg({
      marginTop: `-${SPACING.large}`,
      padding: `${SPACING.large} ${SPACING.small} !important`
    })
  },
  inputContainer: {
    marginBottom: SPACING.tiny,
    '& *': {
      display: 'block',
      width: '100%'
    },
    '& label': {
      marginBottom: SPACING.teeny
    },
    '& label:first-letter': {
      textTransform: 'capitalize'
    },
    '& input, textarea': {
      border: `1px solid ${COLORS.grey}`,
      padding: SPACING.small
    },
    '& textarea': {
      height: '15rem'
    }
  },
  submitButton: {
    display: 'block',
    margin: '0 auto',
    marginTop: SPACING.xl,
    padding: `${SPACING.base} 0`,
    width: '45%',
    backgroundColor: COLORS.red,
    color: COLORS.white,
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    '&:disabled': {
      backgroundColor: '#de685a',
      cursor: 'wait'
    }
  },
  submitButtonSuccess: {
    backgroundColor: '#4BB543 !important',
    cursor: 'pointer !important'
  }
}
