export const COLORS = {
  red: '#ED5342',
  darkRed: '#D73A29',
  darkGrey: '#231F20',
  darkerGrey: '#3D3B3C',
  grey: '#B2B3B4',
  lightGrey: '#DDDDDE',
  lightestGrey: '#F0F0F1',
  taupe: '#625A5B',
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'rgba(0,0,0,0)'
}

export const FONT_SIZES = {
  micro: '.75rem',
  small: '.875rem',
  regular: '1rem',
  large: '1.5rem',
  xl: '2rem',
  xxl: '3rem',
  xxxl: '4rem'
}

export const SPACING = {
  teeny: '.125rem',
  tiny: '.5rem',
  small: '1rem',
  base: '1.5rem',
  large: '2rem',
  xl: '3rem',
  xxl: '4rem',
  xxxl: '5rem',
  letters: {
    small: '.1rem'
  }
}

export const SCREEN_SIZES = {
  xs: 320,
  sm: 570,
  md: 820,
  lg: 1070,
  xl: 1320,
  xxl: 1800
}

export const DROPDOWN_TRANSITION = 'all .25s ease-in-out'
