import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import mailIcon from 'assets/images/mail_icon.svg'
import ReCAPTCHA from 'react-google-recaptcha'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InputContainer = ({ name, value, classes, handleInputChange }) => (
  <div className={classes.inputContainer}>
    <label htmlFor={name}>{name}</label>
    <input
      type='text'
      id={name}
      name={name}
      value={value}
      onChange={handleInputChange}
      required
    />
  </div>
)

class ContactForm extends React.Component {
  state = {
    name: '',
    email: '',
    description: '',
    formStatus: 'ready'
  }

  recaptchaRef = React.createRef()

  handleInputChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  formData() {
    const { name, email, description } = this.state
    return { full_name: name, email: email, description: description }
  }

  handleSubmit = event => {
    event.preventDefault()

    // Ensure no empty strings
    if (Object.values(this.formData()).some(v => !v.trim())) {
      return alert('Please fill in all values.')
    }

    // Execute captcha, if successful the delverPayload
    // callback will be executed.
    this.state.captchaPassed ? this.deliverPayload() : this.recaptchaRef.current.execute()
  }

  deliverPayload = _ => {
    this.setState({
      captchaPassed: true,
      formStatus: 'submitting'
    })

    fetch('https://hooks.zapier.com/hooks/catch/546587/qzmvr4/silent', {
      method: 'POST',
      body: JSON.stringify(this.formData())
    })
      .then(response => {
        this.setState({ formStatus: 'success', name: '', email: '', description: '' })
        setTimeout(_ => {
          this.setState({ formStatus: 'ready' })
        }, 2000)
      })
      .catch(_ => {
        alert(
          'An error occurred while submitting the form. If the problem persists, please shoot us an email to let us know: contact@hint.io'
        )
        this.setState({ formStatus: 'ready' })
      })
  }

  submitButtonContent({ formStatus, classes }) {
    return {
      ready: { content: 'Send', className: '' },
      submitting: {
        content: <FontAwesomeIcon icon='circle-notch' spin />,
        className: ''
      },
      success: {
        content: (
          <div>
            Success! <FontAwesomeIcon icon='check' />
          </div>
        ),
        className: classes.submitButtonSuccess
      }
    }
  }

  render() {
    const { classes, plain } = this.props
    const { formStatus, name, email, description } = this.state
    const submitButtonContent = this.submitButtonContent({
      formStatus: formStatus,
      classes: classes
    })
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className='row no-gutters'>
            <div className={`col-md-10 ${!plain && 'offset-md-1'} ${classes.angleContainer}`}>
              <div className={classes.angle} />
            </div>
            <div className={`col-11 col-lg-3 ${!plain && 'offset-lg-1'} ${classes.left}`}>
              <img src={mailIcon} className={classes.mailIcon} alt='' />
            </div>
            <div className={`col-11 offset-1 offset-lg-0 col-lg-7 ${classes.right}`}>
              <h2>Let's talk about your next project</h2>
              <form onSubmit={this.handleSubmit}>
                <InputContainer
                  name='name'
                  value={name}
                  classes={classes}
                  handleInputChange={this.handleInputChange}
                />
                <InputContainer
                  name='email'
                  value={email}
                  classes={classes}
                  handleInputChange={this.handleInputChange}
                />
                <div className={classes.inputContainer}>
                  <label htmlFor='description'>Description</label>
                  <textarea
                    id='description'
                    name='description'
                    value={description}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>
                <button
                  type='submit'
                  disabled={formStatus !== 'ready'}
                  className={`${classes.submitButton} ${submitButtonContent[formStatus].className}`}
                >
                  {submitButtonContent[formStatus].content}
                </button>
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  onChange={this.deliverPayload}
                  size='invisible'
                  sitekey='6LcrV3EUAAAAACAN7nMbA_htexqV3SA1qnqOxTO8'
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectSheet(style)(ContactForm)
