import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faArrowRight,
  faCircleNotch,
  faCheck,
  faArrowDown,
  faArrowUp,
  faRss,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'

library.add(
  fab,
  faArrowRight,
  faCircleNotch,
  faCheck,
  faArrowDown,
  faArrowUp,
  faRss,
  faChevronLeft,
  faChevronRight
)
